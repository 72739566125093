/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// PRIMENG
// http://geon.github.io/programming/2016/02/24/flexbox-full-page-web-app-layout
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.min.css";
@import "primeng/resources/themes/fluent-light/theme.css";
@import "spectre.css";
@import 'spectre.css/dist/spectre-icons.css';
@import 'maplibre-gl/dist/maplibre-gl.css';
@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import 'css.gg/icons/all.css';

// used for overlays (tooltips)
// https://indepth.dev/posts/1146/tooltip-with-angular-cdk
@import '@angular/cdk/overlay-prebuilt.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  